<template>
  <div class="downloadapp">
    <Tabs></Tabs>
    <div class="banner">
      <div class="w1400">
        <div class="l">
          <h2 style="font-style: italic">
            <i>微信公众号</i>
          </h2>
          <p>
            扫码关注公众号，即刻享受免费资料、实时资讯、
            成绩查询、证书领取等专项服务！
          </p>
        </div>
        <div class="r">
          <img src="../../assets/img/page_background.png" alt />
        </div>
      </div>
    </div>
    <div class="list">
      <div class="box">
        <div class="t">
          <img src="../../assets/img/lyfw.jpg" alt />
          <h2>立优教育官方服务号</h2>
        </div>
        <div class="b">
          <h2>免费资料：</h2>
          <p>一建、二建、药师、安全工程师、监理工程师各类资料</p>
          <h2>学习中心：</h2>
          <p>立优直播、立优课堂、建造师刷题宝</p>
          <h2>我的服务：</h2>
          <p>联系我们、关于我们</p>
        </div>
      </div>
      <div class="box">
        <div class="t">
          <img src="../../assets/img/ej.jpg" alt />
          <h2>立优二级建造师</h2>
        </div>
        <div class="b">
          <h2>免费资料：</h2>
          <p>二级建造师各类资料，帮助考生精确把握重难点及知识点</p>
          <h2>学习中心：</h2>
          <p>立优直播、立优课堂、立优题库</p>
          <h2>我的服务：</h2>
          <p>联系我们、关于我们</p>
        </div>
      </div>

      <div class="box">
        <div class="t">
          <img src="../../assets/img/yj.jpg" alt />
          <h2>立优一级建造师</h2>
        </div>
        <div class="b">
          <h2>学习中心：</h2>
          <p>
            立优教育一级建造师报考指导平台，为您提供-级建造师备考课程、实时资讯、教材资料、报名审核、成绩查询、证书领取等服务。
          </p>
        </div>
      </div>
      <div class="box">
        <div class="t">
          <img src="../../assets/img/aq.jpg" alt />
          <h2>立优安全工程师</h2>
        </div>
        <div class="b">
          <h2>学习中心：</h2>
          <p>
            立优教育安全工程师报考指导平台，为您提供安全工程师备考课程、实时资讯、教材资料、报名审核、成绩查询、证书领取等服务。
          </p>
        </div>
      </div>
      <div class="box">
        <div class="t">
          <img src="../../assets/img/lykt.jpg" alt />
          <h2>立优课堂</h2>
        </div>
        <div class="b">
          <h2>学习中心：</h2>
          <p>
            立优教育旗下线上学习平台专注于一建、二建、安全、医师、药师等领域职业资格考前辅导培训服务。
          </p>
        </div>
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
export default {
  name: "Servewx",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.list {
  width: 1400px;
  margin: 0 auto;
  line-height: 1;
  padding: 60px 0 140px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .box:hover {
    transform: scale(1.03);
  }
  .box {
    padding: 20px;
    background: #ffffff;
    box-shadow: 0px 4px 8px 0px rgba(0, 27, 89, 0.05);
    cursor: pointer;
    transition: all 0.3s;
    .t {
      text-align: center;
      min-width: 223px;
      padding-bottom: 20px;
      border-bottom: 2px solid #f7f9fc;
      img {
        width: 136px;
        height: 136px;
        margin-bottom: 11px;
      }
      h2 {
        font-size: 23px;
        font-family: Microsoft YaqiHei;
        font-weight: bold;
        color: #3e7eff;
      }
    }
    .b {
      h2 {
        margin-top: 20px;
        font-size: 18px;
        font-family: Microsoft YaqiHei;
        font-weight: bold;
        color: #333333;
      }
      p {
        width: 223px;
        font-size: 16px;
        margin-top: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
      }
    }
  }
}
.banner {
  background: #4379f6;
  .w1400 {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .l {
    text-align: left;
    h2 {
      font-size: 50px;
      font-family: Microsoft YaqiHei;
      color: #ffffff;
      font-weight: bold;
      text-shadow: 0px 4px 8px rgba(0, 31, 102, 0.1);
      margin-bottom: 19px;
    }
    p {
      width: 332px;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 27px;
    }
  }
}
.w1400 {
  width: 1400px;
  margin: 0 auto;
}
</style>